<template>
    <div class="p-2">

        <div class="">
            <div class="input-group search">
                <span class="input-group-text"><i class="bx bx-search"></i></span>
                <input type="text" class="form-control" placeholder="What are you looking for...">
            </div>
        </div>

        <div class="d-flex mt-2">
            <ul class="nav nav-pills w-100">
                <li class="nav-item flex-fill" style="max-width:50%">
                    <router-link class="nav-link py-1 font-weight-bold" 
                        style="border-radius: 10px 0 0 10px;background-color: #1EB4F1;color:white;font-size: 16px;"
                        to="/shop/products">Products</router-link>
                </li>
                <li class="nav-item flex-fill" style="max-width:50%">
                    <router-link class="nav-link py-1 font-weight-bold" 
                        style="color:#1B628C;background-color: white;border-radius: 0 10px 10px 0;font-size: 16px;" 
                        to="/shop/stores"
                    >
                    Shops</router-link>
                </li>
            </ul>
        </div>

        <div class="d-flex align-items-center mb-2" style="gap:10px">
            <div class="text-white mr-auto" style="border:2px solid #FFAD32;border-radius:10px;padding:.35em 2em">
                Filter  
            </div>
            <div class="d-flex h-100" style="">
                <div class="text-white d-flex" @click="mode = 'list'" :style="`${mode == 'list' ? 'background-color:#1B628C;' : ''}border:2px solid #FFAD32;border-radius:10px 0 0 10px;padding:.35em 1em;border-right:unset !important`">
                    <i class="ti ti-list m-auto" style="font-size:18px"></i>
                </div> 
                <div class="text-white d-flex" @click="mode = 'grid'" :style="`${mode == 'grid' ? 'background-color:#1B628C;' : ''}border:2px solid #FFAD32;border-radius:0 10px 10px 0;padding:.35em 1em;border-left:unset !important`">
                    <i class="ti ti-grid-dots m-auto" style="font-size:18px"></i>
                </div> 
            </div>
        </div>

        <div class="d-flex flex-column" style="gap:10px" v-if="mode == 'list'">
            <a :href="item.pr_hyperlink" target="_blank" class="d-flex align-items-center" style="gap:10px" v-for="item in product_list">
                <div class="position-relative">
                    <img :src="item.pr_picture"
                        style="height: 80px;width: 80px;background-color: #D9D9D9;border-radius: 10px;object-fit: cover;" />
                </div>
                <div class="flex-fill">
                    <div class="d-flex justify-content-between w-100" style="gap:10px">
                        <h4 class="text-white font-weight-bolder mb-0">{{ item.pr_name }}</h4>
                        <h4 class="text-white font-weight-bolder mb-0">${{ item.pr_price }}</h4>
                    </div>
                    <h6 class="text-white font-weight-normal" style="margin:.5em 0">{{ item.pr_details }}</h6> 
                </div>
            </a>
        </div>

        <div v-else class="row" style="gap:10px 0">
            <div class="col-6" v-for="item in product_list">
                <a :href="item.pr_hyperlink" target="_blank">
                    <img :src="item.pr_picture"
                        style="height: 150px;width: 100%;background-color: #D9D9D9;border-radius: 10px;object-fit: cover;" /> 
                    <div class="flex-fill mt-1">
                        <div class="d-flex justify-content-between w-100" style="gap:10px">
                            <h4 class="text-white font-weight-bolder mb-0">{{ item.pr_name }}</h4>
                            <h4 class="text-white font-weight-bolder mb-0">${{ item.pr_price }}</h4>
                        </div>
                        <h6 class="text-white font-weight-normal" style="margin:.5em 0">{{ item.pr_details }}</h6> 
                    </div>
                </a>
            </div>
        </div>

    </div>
</template>

<script>

import store from '@/store'

export default {
    computed: {  
        product_list() {
            return store.state.store_api.PRODUCT_LIST || []
        }
    },
    mounted(){
        store.dispatch('store_api/GetProductList', {
            
        })
    },
    data(){
        return {
            mode: 'list'
        }
    }
}

</script>

<style>
.input-group.search span {
    border-right: unset;
    border-color: #FFAD32;
    border-radius: 10px 0 0 10px;
    font-size: 24px;
    color: #FFAD32;
    background-color: transparent;
}

.input-group.search input {
    margin-left: 0;
    padding-left: 0;
    border-left: unset;
    border-radius: 0 10px 10px 0;
    border-color: #FFAD32;
    height: 40px;
    color: white;
    background-color: transparent !important;
}
</style>